<template>
  <div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapMutations, mapState } from 'vuex'
import { colors } from '@/utils/colors'
import Bus from '@/helpers/bus'

export default {
  name: 'Socket',
  data: () => ({
    items: [],
    foderSearch: '',
    userId: '',
    connection: null,
    currentRoute: '',
    socketJsonData: {},
    userId: ''
  }),
  watch: {
  },
  created () {
    // Esta línea es para conectarse al socket de la api en el servidor de producción
    // var socket = io.connect('http://172.16.1.247:5000/')
    // var socket = io.connect('https://legaladdiuva.com/')
    //  var socket = io.connect('https://seguimientooperativo.site/')
    // Esta línea es para conectarse al socket de la api local (si se conecta a un ngrok o se corre local la api)
    //var socket = io.connect('http://192.168.11.113:5000/')

    const socket = io.connect(process.env.VUE_APP_SOCKET)

    this.userid = localStorage.getItem('user')
    
    const environment = localStorage.getItem('environment')

    switch (environment) {
      // Conexión para desarrollo
      case 'dev':
        Bus.$on('create-detail-channel', (contractId) => {
          socket.on(`dev_update_document_${contractId}`, data => {
            Bus.$emit('update-attach-files')
          })
        })
        socket.on(`dev_user_notifications_${this.userid}`, data => {
          localStorage.setItem('notificationNumber', Number(localStorage.getItem('notificationNumber')) + 1)
          Bus.$emit('update-notifications-number')
          if (data.type === '7') {
            Bus.$emit('update-contracts-socket')
          }
          // this.$toast(data.message)
        })
      break
      // Conexión para servidor QA (113)
      case 'qa':
        Bus.$on('create-detail-channel', (contractId) => {
          socket.on(`qa_update_document_${contractId}`, data => {
            Bus.$emit('update-attach-files')
          })
        })
        socket.on(`qa_user_notifications_${this.userid}`, data => {
          localStorage.setItem('notificationNumber', Number(localStorage.getItem('notificationNumber')) + 1)
          Bus.$emit('update-notifications-number')
          // this.$toast(data.message)
        })
      break
      // Conexión para servidor prod
      case 'prod':
        Bus.$on('create-detail-channel', (contractId) => {
          socket.on(`prod_update_document_${contractId}`, data => {
            Bus.$emit('update-attach-files')
          })
        })
        socket.on(`prod_user_notifications_${this.userid}`, data => {
          localStorage.setItem('notificationNumber', Number(localStorage.getItem('notificationNumber')) + 1)
          Bus.$emit('update-notifications-number')
          // this.$toast(data.message)
        })
      break

    }

    socket.on('connect', () => {
      console.log('socket connected')
    })
    socket.on('disconnect', () => {
      console.log('socket disconnected')
    })

  },
  computed: {
    ...mapState(['navNotificationsNumber']),
  },
  methods: {
    ...mapMutations({ setSnackbar: 'SET_SNACKBAR' }),
    ...mapMutations({ setNavNotificationsNumber: 'SET_NAV_NOTIFICATIONS_NUMBER'})
  }
}
</script>
<style lang="scss">
.Vue-Toastification__toast--default{
  background-color: #E75CE0;
  font-weight: bold;
  max-width: 50%;
}
.Vue-Toastification__toast-body{
  background-color: #E75CE0;
  font-weight: bold;
  font-size: 13px !important; 
  word-wrap: break-word;;
}
</style>
