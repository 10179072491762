const countries = {
  CO: 'colombia',
  MX: 'mexico',
  CL: 'chile',
  AR: 'argentina',
  BO: 'bolivia',
  BR: 'panama',
  CR: 'costa_rica',
  EC: 'ecuador',
  SV: 'salvador',
  GT: 'guatemala',
  HN: 'honduras',
  NI: 'nicaragua',
  PY: 'paraguay',
  PE: 'peru',
  PR: 'puerto_rico',
  DO: 'republica_dominicana',
  UY: 'uruguay',
  // vocare
  VMX: 'mexico',
  VCO: 'colombia',
  VGT: 'guatemala'
}

export {
  countries
}
