<template>
  <div class="notifications-card" v-if="showNotifications" v-click-outside="closeNotifications">
    <div style="text-align: end">
      <v-btn
          text
          icon
          class="ma-2"
          color="primary"
          @click="closeNotifications()"
        >
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
    </div>
    <div v-if="navNotifications.length > 0">
      <v-data-iterator
          :items="navNotifications"
          no-data-text="No hay notificaciones nuevas"
          :items-per-page="5"
          :footer-props="{itemsPerPageOptions:[0]}"
        >
          <template v-slot:default="props">
              <div v-for="(notiItem, index) in props.items" :key="index">
              <v-card class="inside-card my-2" @click="redirecTo(notiItem)">
                <v-card-title>
                  <h5>
                    {{ notiItem.title }}
                    <v-icon
                      small
                      color="primary"
                    >
                      mdi-bell-outline
                    </v-icon>
                  </h5>
                </v-card-title>
                <v-card-text>
                  <span> {{ notiItem.notification }} </span>
                </v-card-text>
              </v-card>
              </div>
          </template>
      </v-data-iterator>
    </div>
    <div v-else>
      <v-card class="my-2">
        <v-card-title>
          <h5>
           No hay notificaciones nuevas
          </h5>
        </v-card-title>
      </v-card>
    </div>
  </div>
</template>

<script>
/* Bus */
import Bus from '@/helpers/bus'
import { mapState } from 'vuex'

export default {
  name: 'NavNotifications',
  components: {
  },

  watch: {
  },

  data () {
    return {
      showNotifications: false
    }
  },

  created () {
    Bus.$on('show-nav-notifications', (value) => {
      if (this.showNotifications) {
        this.showNotifications = !this.showNotifications
      } else {
        this.showNotifications = value
      }
    })
  },

  computed: {
    ...mapState(['navNotifications'])
  },

  methods: {
    closeNotifications () {
      Bus.$emit('show-background-notifications')
      this.showNotifications = false
    },
    redirecTo (item) {
      this.closeNotifications()
      const idDocument = this.$route.params.id
      this.$router.push(`/portal/contracts/${item.document}/`)
      if (idDocument !== undefined) {
        location.reload()
      }
    }
  },
  directives: {
    'click-outside': {
      bind (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
          // Verificar si el clic ocurrió fuera del elemento
          if (!(el === event.target || el.contains(event.target))) {
            // Ejecutar la función vinculada cuando se hace clic fuera del elemento
            vnode.context[binding.expression]()
          }
        }
        // Agregar un evento de clic al documento
        document.body.addEventListener('click', el.clickOutsideEvent)
      },
      unbind (el) {
        // Eliminar el evento de clic del documento cuando se desvincula la directiva
        document.body.removeEventListener('click', el.clickOutsideEvent)
      }
    }
  }

}
</script>

<style scoped>
.notifications-card{
  margin-top: -20px;
  max-width: 300px;
  position: absolute;
  z-index: 5;
  background-color: white;
  align-items: center;
}
.style-card{
  max-height: 100px;
}
.inside-card{
  margin-right: 10px;
  margin-left: 10px;
  background-color: #F3F7FD;
  box-shadow: 4;
}
</style>
