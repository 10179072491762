const paths = [
  {
    link: '/portal/contracts',
    permissions: [1, 2, 3],
    title: 'Mis Contratos'
  },
  {
    link: '/portal/templates',
    permissions: [1, 2],
    title: 'Plantillas'
  },
  {
    link: '/portal/configurations',
    permissions: [1],
    title: 'Configuraciones'
  }
]

export {
  paths
}
