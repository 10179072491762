<template>
  <div class="loader-container">
    <v-row align="center" justify="center" class="fill-height">
      <v-col cols="auto">
        <v-overlay v-if="type === 'overlay'">
        </v-overlay>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'Loader',

  props: {
    type: {
      type: String,
      default: () => 'overlay',
      validator: (value) => {
        return ['overlay', 'single'].indexOf(value) !== -1
      }
    }
  }
}
</script>

<style>
</style>
