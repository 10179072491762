<template>
  <div>
    <BaseLayout>
      <template slot="navbar">
        <Navbar :links="this.links" :name="this.name" :area="this.area" :country="country" :menu-items="this.items" />
      </template>
      <template slot="content">
        <v-row no-gutters justify="space-between">
          <v-col cols="12" sm="8" md="8"></v-col>
          <v-col cols="12" sm="4" md="4">
            <NavNotifications />
          </v-col>
        </v-row>
        <router-view />
      </template>
    </BaseLayout>
    <Socket />
  </div>
</template>

<script>

/* Components */
import BaseLayout from '@/layouts/BaseLayout'
import Navbar from '@/components/Base/Navbar'
import Socket from '@/components/Base/Socket'
import NavNotifications from '@/components/Base/NavNotifications'
import { mapState, mapActions } from 'vuex'

/* Utils */
import { profiles } from '@/utils/profiles'
import { paths } from '@/utils/paths'

export default {
  name: 'Portal',
  components: {
    BaseLayout,
    Navbar,
    Socket,
    NavNotifications
  },

  data () {
    return {
      links: [
      ],
      name: '',
      area: '',
      country: '',
      items: [
        { title: 'Ver perfil' },
        { title: 'Cerrar sesión' }
      ]
    }
  },

  computed: {
    ...mapState(['profile'])
  },

  created () {
    const currentProfile = profiles.find(item => item.name === this.profile, this)
    const pathsValidated = paths.filter(
      path => path.permissions.includes(currentProfile.permission)
    )
    this.links = pathsValidated
    this.name = localStorage.getItem('name')
    this.area = localStorage.getItem('area')
    this.country = localStorage.getItem('country')
    this.getCountries()
    this.getTemplateTypes()
    this.getAllFolders()
  },

  methods: {
    ...mapActions(['getCountries', 'getTemplateTypes', 'getAllFolders'])
  }
}
</script>

<style>

</style>
