<template>
  <div>
    <v-dialog
      max-width='800px'
      v-model="profileDialog"
      @click:outside="closeDialog()"
      @keydown.esc="closeDialog()"
    >
      <v-card class="pa-4" max-width="auto" height="auto">
        <v-card-title class="pt-4 pb-8 center-dialog centered-dialog__card">
          <v-icon class="mx-2">mdi-account-multiple-outline</v-icon>
          <h4>Información del usuario {{ profileForm.username }}</h4>
          <v-icon class="mx-2">mdi-account-multiple-outline</v-icon>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-form ref="profileForm" v-model="profileForm.isValid">
            <v-row no-gutters>
              <v-col class="px-2 py-0" cols="4">
                <v-text-field
                  class="form-field mt-2"
                  name="name"
                  label="Nombre"
                  background-color="white"
                  v-model="profileForm.name"
                />
              </v-col>
              <v-col class="px-2 py-0" cols="4">
                <v-text-field
                  class="form-field mt-2"
                  name="lastName"
                  label="Apellido"
                  background-color="white"
                  v-model="profileForm.lastName"
                />
              </v-col>
              <v-col class="px-2 py-0" cols="4">
                <v-text-field
                  class="form-field mt-2"
                  :rules="emailRules"
                  name="email"
                  label="Email"
                  background-color="white"
                  v-model="profileForm.email"
                  type="email"
                />
              </v-col>
              <v-col class="px-2 py-0" cols="4">
                <v-text-field
                  class="form-field mt-2"
                  :rules="passwordRules"
                  name="password"
                  label="Contraseña"
                  background-color="white"
                  v-model="profileForm.password"
                  :append-icon="passwordVisibility ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                  :type="passwordVisibility ? 'text' : 'password'"
                  @click:append="() => (passwordVisibility = !passwordVisibility)"
                  :disabled="passwordDisabled"
                  hint="Mínimo debe contener un número, una mayúscula y 5 caractéres"
                  ref="passwordRef"
                />
              </v-col>
              <v-col class="px-2 py-0" cols="4" v-if="!passwordDisabled">
                <v-text-field
                  class="form-field mt-2"
                  :rules="passwordConfirmationRules()"
                  name="password"
                  label="Verificar contraseña"
                  background-color="white"
                  v-model="profileForm.password2"
                  :append-icon="passwordVisibility2 ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                  :type="passwordVisibility2 ? 'text' : 'password'"
                  :disabled="passwordDisabled"
                  @click:append="() => (passwordVisibility2 = !passwordVisibility2)"
                  @change="passwordConfirmationRules()"
                />
              </v-col>
              <v-col class="px-2 py-0" cols="4">
                <v-select
                  class="form-field mt-2"
                  :rules="textFieldRules"
                  :items="countryList"
                  item-text="name"
                  item-value="id"
                  label="País"
                  disabled
                  background-color="white"
                  v-model="profileForm.country"
                />
              </v-col>
              <v-col class="px-2 py-0" cols="4">
                <v-select
                  class="form-field mt-2"
                  :rules="textFieldRules"
                  :items="areaList"
                  item-text="name"
                  item-value="id"
                  label="Area"
                  disabled
                  background-color="white"
                  v-model="profileForm.area"
                />
              </v-col>
              <v-col class="center-dialog" cols="12">
                <span class="pointer change-state" @click="passwordDisabled=!passwordDisabled"> Cambiar contraseña </span>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-row no-gutters justify="center">
            <v-col cols="auto" class="pt-6 mr-3">
              <v-btn
                depressed
                color="default-btn accent"
                @click="closeDialog()"
              >
                Cerrar
              </v-btn>
            </v-col>
            <v-col cols="auto" class="pt-6 ml-3">
              <v-btn
                depressed
                color="default-btn primary"
                :loading="buttonLoader"
                @click="updateUserInformation()"
              >
                Actualizar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div v-if="loader">
      <Loader />
    </div>
  </div>
</template>

<script>
/* Bus */
import Bus from '@/helpers/bus'

import { mapActions, mapMutations } from 'vuex'
import { colors } from '@/utils/colors'

/* Components */
import Loader from '@/components/Base/Loader'

export default {
  name: 'confirmDialog',
  components: { Loader },
  data: () => ({
    loader: false,
    profileDialog: false,
    items: [],
    buttonLoader: false,
    userId: '',
    profileForm: {
      password: '',
      password2: ''
    },
    passwordVisibility: false,
    passwordVisibility2: false,
    passwordDisabled: false,
    textFieldRules: [
      v => !!v || 'Este campo es requerido'
    ],
    emailRules: [
      v => !v || /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(v) || 'El email debe ser válido'
    ],
    passwordRules: [
      v => !!v || 'Este campo es requerido',
      v => (v && v.length >= 5) || 'La contraseña debe tener 5 o más caracteres',
      v => /(?=.*[A-Z])/.test(v) || 'La contraseña debe de contener al menos una mayúscula',
      v => /(?=.*\d)/.test(v) || 'La contraseña debe de contener al menos 1 número'
    ],
    areaList: [],
    countryList: []
  }),
  watch: {
  },
  created () {
    this.userId = localStorage.getItem('user')
    Bus.$on('open-profile', () => {
      this.getUserInformation()
      this.getAreas()
      this.getCountries()
    })
  },
  computed: {
  },

  methods: {
    ...mapActions('administration', ['getAdminUser', 'updateAdminUsers', 'getAdminAreas', 'getAdminCountries']),
    ...mapMutations({ setSnackbar: 'SET_SNACKBAR' }),

    passwordConfirmationRules () {
      return [
        v => this.profileForm.password === this.profileForm.password2 || 'Las contraseñas deben coincidir.'
      ]
    },

    getAreas () {
      this.getAdminAreas()
        .then(response => {
          this.areaList = response.data
        })
    },

    getCountries () {
      this.getAdminCountries()
        .then(response => {
          this.countryList = response.data
        })
    },

    getUserInformation () {
      const request = {
        id: this.userId
      }
      this.getAdminUser(request)
        .then(response => {
          this.profileDialog = true
          this.profileForm = response.data
          this.profileForm.password = ''
          this.profileForm.password2 = ''
          this.passwordDisabled = true
        })
    },

    updateUserInformation () {
      this.buttonLoader = true
      const request = {}
      const auxForm = this.profileForm

      Object.keys(auxForm).map(function (key, index) {
        if (key !== 'isValid' && auxForm[key] !== '' && auxForm[key] !== '') {
          request[key] = auxForm[key]
        }
      })

      this.updateAdminUsers(request)
        .then(() => {
          this.buttonLoader = false
          this.getUserInformation()
          this.setSnackbar({
            text: 'Usuario actualizado correctamente',
            timeout: 5000,
            showing: true,
            color: colors.success
          })
        })
        .catch(error => {
          this.buttonLoader = false
          const data = error.response.data
          if (data.email) {
            this.setSnackbar({
              text: data.email.message,
              timeout: 5000,
              showing: true,
              color: colors.error
            })
          } else {
            this.setSnackbar({
              text: 'No se pudo actualizar el usuario',
              timeout: 5000,
              showing: true,
              color: colors.error
            })
          }
        })
    },

    closeDialog () {
      this.profileDialog = false
      this.$refs.profileForm.resetValidation()
      this.profileForm = {}
      this.$refs.passwordRef.reset()
      this.passwordVisibility = false
      this.passwordVisibility2 = false
      this.profileForm.password = '********'
      this.profileForm.password2 = '********'
      this.passwordDisabled = true
    }
  }
}
</script>
<style lang="scss">
.centered-dialog__card {
  padding: 20px;
  margin: 0;
  overflow: hidden;
}
.center-dialog{
  text-align: center;
  justify-content: center;
}
.change-state {
  color: #435CC8;
  cursor: pointer;
}
.change-state:active {
    color: rgb(189, 189, 255);
    cursor: wait;
}
</style>
