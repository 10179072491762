import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VAppBar,{staticClass:"navbar",attrs:{"app":"","color":"dark","flat":"","dark":"","height":"60"}},[_c(VAppBarNavIcon,{staticClass:"navbar-logo"},[_c('img',{attrs:{"src":require("@/assets/images/navbar-logo.png"),"alt":"Addiuva"}})]),_c(VToolbarTitle,{staticClass:"navbar-title"},[_vm._v("Plataforma Legal")]),_c(VTabs,{staticClass:"d-none d-md-flex"},_vm._l((_vm.links),function(link){return _c(VTab,{key:link.link,attrs:{"to":link.link},on:{"click":function($event){return _vm.resetGoBackState(link.link)}}},[_vm._v(" "+_vm._s(link.title)+" ")])}),1),_c(VSpacer),_c('div',{on:{"click":function($event){return _vm.obtainNavNotifications()}}},[_c(VBadge,{staticClass:"subtitle-action",attrs:{"content":_vm.notificationsNumber,"value":_vm.notificationsNumber,"color":"secondary","overlap":""}},[_c(VIcon,[_vm._v(" mdi-bell ")])],1)],1),_c('div',{staticClass:"profile-description d-none d-md-block"},[_c('div',{staticClass:"name"},[(this.getCountry)?_c('img',{staticClass:"flag",attrs:{"src":require(("@/assets/images/flags/flag_" + _vm.getCountry + ".png")),"alt":"Country"}}):_c(VIcon,[_vm._v("mdi-flag")]),_vm._v(" "+_vm._s(this.getFullName)+" ")],1),_c('p',{staticClass:"area ma-0"},[_vm._v(_vm._s(this.area))])]),_c(VMenu,{attrs:{"rounded":"","bottom":"","offset-y":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"menu",attrs:{"dark":"","icon":"","x-small":""}},'v-btn',attrs,false),on),[_c(VIcon,{staticClass:"d-none d-md-flex"},[_vm._v("mdi-menu-down")]),_c(VIcon,{staticClass:"d-md-none"},[_vm._v("mdi-menu")])],1)]}}])},[_c(VList,{staticClass:"pa-5",attrs:{"dense":"","rounded":""}},[_c('div',{staticClass:"profile-description-inner mb-3 d-md-none"},[_c('div',{staticClass:"name-inner"},[(this.getCountry)?_c('img',{staticClass:"flag",attrs:{"src":require(("@/assets/images/flags/flag_" + _vm.getCountry + ".png")),"alt":"Country"}}):_c(VIcon,[_vm._v("mdi-flag")]),_vm._v(" "+_vm._s(this.getFullName)+" ")],1),_c('p',{staticClass:"area ma-0"},[_vm._v(_vm._s(this.area))]),_c(VDivider)],1),_vm._l((_vm.links),function(link){return _c(VListItem,{key:link.link,staticClass:"d-md-none",attrs:{"to":link.link}},[_c(VListItemTitle,[_vm._v(_vm._s(link.title))])],1)}),_vm._l((_vm.menuItems),function(item,i){return _c(VListItem,{key:i},[_c(VListItemTitle,{staticClass:"pointer",on:{"click":function($event){return _vm.profileOrLogout(i)}}},[_vm._v(_vm._s(item.title))])],1)})],2)],1),_c('ProfileDialog')],1),(_vm.isLoading)?_c('Loader'):_vm._e(),(_vm.showNotificationsBackground)?_c('BackgroundShape'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }