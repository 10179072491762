<template>
<div>
  <v-app-bar
    app
    class="navbar"
    color="dark"
    flat
    dark
    height="60"
  >
    <v-app-bar-nav-icon class="navbar-logo">
      <img
        src="@/assets/images/navbar-logo.png"
        alt="Addiuva"
      >
    </v-app-bar-nav-icon>
    <v-toolbar-title class="navbar-title">Plataforma Legal</v-toolbar-title>
    <v-tabs class="d-none d-md-flex">
      <v-tab
        v-for="link in links"
        :key="link.link"
        :to="link.link"
        @click="resetGoBackState(link.link)"
      >
        {{ link.title }}
      </v-tab>
    </v-tabs>

    <v-spacer></v-spacer>
    <div @click="obtainNavNotifications()">
      <v-badge
        class="subtitle-action"
        :content="notificationsNumber"
        :value="notificationsNumber"
        color="secondary"
        overlap
      >
        <v-icon>
          mdi-bell
        </v-icon>
      </v-badge>
    </div>
    <div class="profile-description d-none d-md-block">
      <div class="name">
        <img
          v-if="this.getCountry"
          class="flag"
          :src="require(`@/assets/images/flags/flag_${getCountry}.png`)"
          alt="Country"
        />
        <v-icon v-else>mdi-flag</v-icon>
        {{ this.getFullName }}
      </div>
      <p class="area ma-0">{{ this.area }}</p>
    </div>

    <v-menu
      rounded
      bottom
      :offset-y="true"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          dark
          icon
          x-small
          v-bind="attrs"
          v-on="on"
          class="menu"
        >
          <v-icon class="d-none d-md-flex">mdi-menu-down</v-icon>
          <v-icon class="d-md-none">mdi-menu</v-icon>
        </v-btn>
      </template>

      <v-list dense rounded class="pa-5">
        <div class="profile-description-inner mb-3 d-md-none">
          <div class="name-inner">
            <img
              v-if="this.getCountry"
              class="flag"
              :src="require(`@/assets/images/flags/flag_${getCountry}.png`)"
              alt="Country"
            />
            <v-icon v-else>mdi-flag</v-icon>
            {{ this.getFullName }}
          </div>
          <p class="area ma-0">{{ this.area }}</p>
          <v-divider></v-divider>
        </div>
        <v-list-item
          v-for="link in links"
          :key="link.link"
          :to="link.link"
          class="d-md-none"
        >
          <v-list-item-title>{{ link.title }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-for="(item, i) in menuItems"
          :key="i"
        >
          <v-list-item-title  class="pointer" @click="profileOrLogout(i)">{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <ProfileDialog />
  </v-app-bar>
    <Loader v-if="isLoading" />
    <BackgroundShape v-if="showNotificationsBackground"/>
  </div>
</template>

<script>

/* Utils */
import { countries } from '@/utils/countries'
import ProfileDialog from '@/components/Base/ProfileDialog'
import Loader from '@/components/Base/Loader'
import BackgroundShape from '@/components/Base/BackgroundShape'

/* Bus */
import Bus from '@/helpers/bus'
import { mapActions } from 'vuex'

export default {
  name: 'Navbar',
  components: { ProfileDialog, Loader, BackgroundShape },
  props: {
    name: {
      type: String,
      required: true,
      default: () => ''
    },
    area: {
      type: String,
      required: true
    },
    links: {
      type: Array,
      required: true
    },
    menuItems: {
      type: Array,
      required: true
    },
    country: {
      type: String,
      required: true,
      default: () => 'MX'
    }
  },

  data () {
    return {
      isLoading: false,
      notificationsNumber: 0,
      showNotificationsBackground: false
    }
  },
  watch: {
    notificationsNumber: {
      handler (val) {
      },
      deep: true
    }
  },

  created () {
    Bus.$on('update-notifications-number', () => {
      this.notificationsNumber = localStorage.getItem('notificationNumber')
    })
    Bus.$on('show-background-notifications', () => {
      this.showNotificationsBackground = false
    })
    this.notificationsNumber = localStorage.getItem('notificationNumber')
  },

  computed: {
    getCountry () {
      return countries[this.country]
    },

    getFullName () {
      return !this.name.trim() || this.name.length === 0 ? 'Usuario' : this.name
    }
  },

  methods: {
    ...mapActions(['getNavNotifications']),

    obtainNavNotifications () {
      this.isLoading = true
      const request = {
        notifications: localStorage.getItem('notificationNumber')
        // notifications: 15
      }
      this.getNavNotifications(request)
        .then(() => {
          this.isLoading = false
          this.showNotificationsBackground = true
          Bus.$emit('show-nav-notifications', true)
          localStorage.setItem('notificationNumber', 0)
          Bus.$emit('update-notifications-number')
        })
        .catch(() => {
          this.isLoading = false
        })
    },

    profileOrLogout (i) {
      if (i === 0) {
        Bus.$emit('open-profile')
      } else if (i === 1) {
        this.logout()
      }
    },

    logout () {
      localStorage.clear()
      this.$router.push('/login')
    },

    resetGoBackState (link) {
      Bus.$emit('navegation-clear')
      Bus.$emit('template-navegation-clear')
    }
  }
}
</script>

<style lang="scss">

.navbar {
  height: 60px;
  padding: 0 63px !important;

  .v-tab {
    text-transform: none;
    font-weight: 400;
  }
  .v-tab--active {
    font-weight: 500;
  }

  .v-tabs-slider {
    background-color: var(--v-secondary-base);
    margin-top: -2px;
    height: 4px !important;
    border-radius: 4px;
  }
  .navbar-title {
    font-weight: 500;
    font-size: 16px;
    padding: 7px !important;
    width: 100%;
    max-width: max-content;
    margin-right: 4%;
  }

  .profile-description {
    margin: 0 18px;
    font-weight: 500;
    max-height: 60px;
  }

  .profile-description-inner {
    margin: 0 18px;
    font-weight: 500;
    font-size: 10px;
    max-height: 60px;
  }

  .flag {
    margin-right: 8px;
  }

  .name {
    white-space: nowrap;
  }

  .area {
    font-weight: 400;
    font-size: 14px;
  }
}

@media screen and (max-width: 1380px) {
  .navbar {
    font-size: 13px;
    .v-tab {
      font-size: 13px;
    }

    .v-menu {
      padding: 10px;
    }

    .navbar-title {
      font-size: 16px;
    }

    .area {
      font-size: 13px;
    }

    .navbar-logo img {
      height: 25px !important;
      width: 25px !important;
    }
  }
}
</style>
