import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataIterator } from 'vuetify/lib/components/VDataIterator';
import { VIcon } from 'vuetify/lib/components/VIcon';
import ClickOutside from 'vuetify/lib/directives/click-outside';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showNotifications)?_c('div',{directives:[{def: ClickOutside,name:"click-outside",rawName:"v-click-outside",value:(_vm.closeNotifications),expression:"closeNotifications"}],staticClass:"notifications-card"},[_c('div',{staticStyle:{"text-align":"end"}},[_c(VBtn,{staticClass:"ma-2",attrs:{"text":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.closeNotifications()}}},[_c(VIcon,[_vm._v("mdi-close-circle")])],1)],1),(_vm.navNotifications.length > 0)?_c('div',[_c(VDataIterator,{attrs:{"items":_vm.navNotifications,"no-data-text":"No hay notificaciones nuevas","items-per-page":5,"footer-props":{itemsPerPageOptions:[0]}},scopedSlots:_vm._u([{key:"default",fn:function(props){return _vm._l((props.items),function(notiItem,index){return _c('div',{key:index},[_c(VCard,{staticClass:"inside-card my-2",on:{"click":function($event){return _vm.redirecTo(notiItem)}}},[_c(VCardTitle,[_c('h5',[_vm._v(" "+_vm._s(notiItem.title)+" "),_c(VIcon,{attrs:{"small":"","color":"primary"}},[_vm._v(" mdi-bell-outline ")])],1)]),_c(VCardText,[_c('span',[_vm._v(" "+_vm._s(notiItem.notification)+" ")])])],1)],1)})}}],null,false,1902860301)})],1):_c('div',[_c(VCard,{staticClass:"my-2"},[_c(VCardTitle,[_c('h5',[_vm._v(" No hay notificaciones nuevas ")])])],1)],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }